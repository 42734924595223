<template>
  <div>
    <!-- Button -->
    <div class="top-table-container">
      <el-input v-model="search" prefix-icon="el-icon-search" class="search" placeholder="Buscar por palabra clave..." />
      <el-button @click.prevent="formNuevo">Nuevo Diametro</el-button>
    </div>

    <!-- Table -->
    <el-table empty-text="Sin Resultados" :data="diametrosData" border style="width: 100%">
      <el-table-column prop="id" width="50" label="ID" align="center"> </el-table-column>
      <el-table-column prop="descripcion" label="Descripción" sortable></el-table-column>
      <el-table-column prop="valor" align="center" label="Valor" width="120"></el-table-column>
      <el-table-column fixed="right" label="Acciones" width="220" align="center">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-edit" @click.prevent="formEditar(scope.$index, scope.row)">Editar</el-button>
          <el-button size="mini" type="danger" icon="el-icon-delete" @click.prevent="eliminar(scope.$index, scope.row)">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginate">
      <el-pagination background layout="prev, pager, next" @current-change="paginate" :page-size="pageSize" :total="total"> </el-pagination>
    </div>

    <!-- Modal => Nuevo - Editar -->
    <el-dialog :title="tituloModal" :visible.sync="modal" width="35%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="form" :rules="rules" ref="form" label-position="top">
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item prop="id" label="ID" v-show="operacion == 'editar'">
              <el-input :disabled="true" v-model="form.id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="Descripción" prop="descripcion">
              <el-input v-model="form.descripcion" placeholder="Descripción del diametro"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valor" prop="valor">
              <el-input v-model.number="form.valor" placeholder="Valor en pulgadas" maxlength="2" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.prevent="modal = false">{{ "Cancelar" }}</el-button>
        <el-button v-loading.fullscreen.lock="loading" type="primary" @click.prevent="guardar('form')">{{ accion }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "Diametros",
  data() {
    return {
      modal: false,
      search: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      operacion: "",
      accion: "",
      tituloModal: "",
      diametros: [],
      diametro: {
        id: "",
        descripcion: "",
        valor: "",
      },
      form: {
        id: '',
        descripcion: '',
        valor: '',
      },
      rules: {
        descripcion: [{ required: true, message: "Este campo es requerido.", trigger: "blur" }],
        valor: [
          { required: true, message: "Este campo es requerido.", trigger: "blur" },
          { type: "number", message: "El campo metros solo recibe numeros." },
        ],
      },
    };
  },
  created() {
    this.mostrar();
  },
  computed: {
    searching() {
      if (!this.search) {
        this.total = this.diametros.length;
        return this.diametros;
      }
      this.page = 1;
      return this.diametros.filter(data => data.descripcion.toLowerCase().includes(this.search.toLowerCase()));
    },
    diametrosData() {
      this.total = this.searching.length;
      return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
  },
  methods: {
    // MÉTODOS PARA EL CRUD
    mostrar() {
      axios
        .get("diametros")
        .then(res => (this.diametros = res.data))
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido mostrar la data, ah ocurrido un error inesperado`,
          });
        });
    },
    crear() {
      const datos = {
        descripcion: this.form.descripcion,
        valor: this.form.valor,
      };
      axios
        .post("diametros", datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `<strong>${datos.descripcion}</strong> ha sido <strong>creada</strong> con exito!`,
                type: "success",
              });
              this.mostrar();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido crear el diametro, ah ocurrido un error inesperado`,
          });
        });
      this.diametro.descripcion = "";
      this.diametro.valor = "";
    },
    editar() {
      const id = this.form.id;
      const datos = {
        descripcion: this.form.descripcion,
        valor: this.form.valor,
      };
      axios
        .put(`diametros/${id}`, datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `<strong>${datos.descripcion}</strong> ha sido <strong>actualizada</strong> con exito!`,
                type: "success",
              });
              this.mostrar();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido editar el diametro, ah ocurrido un error inesperado`,
          });
        });
    },
    eliminar(_, data) {
      const id = data.id;
      this.$confirm("Esta seguro que desea eliminar este registro?", "Eliminar Diametro", {
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(res => {
          if (res) {
            axios
              .delete(`diametros/${id}`)
              .then(res => {
                if (res) {
                  this.loading = true;
                  setTimeout(() => {
                    this.loading = false;
                    this.$notify({
                      title: "Éxito",
                      dangerouslyUseHTMLString: true,
                      message: `<strong>${data.descripcion}</strong> ha sido <strong>eliminada</strong> con exito!`,
                      type: "success",
                    });
                    this.mostrar();
                  }, 1500);
                }
              })
              .catch(err => {
                console.log(err);
                this.$notify.error({
                  title: "Error",
                  dangerouslyUseHTMLString: true,
                  message: `No se ha podido eliminar el diametro, ah ocurrido un error inesperado`,
                });
              });
          }
        })
        .catch(err => console.log(err));
    },
    //Botones y Formularios
    guardar(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if (this.operacion == "crear") this.crear();
          if (this.operacion == "editar") this.editar();
          this.modal = false;
          this.$refs[form].resetFields();
        } else {
          return false;
        }
      });
    },
    formNuevo() {
      this.form.id = '';
      this.form.descripcion = '';
      this.form.valor = '';
      this.modal = true;
      this.operacion = "crear";
      this.accion = "Guardar";
      this.tituloModal = "Nuevo Diametro";

      if (typeof this.$refs.form != "undefined") {
        this.$refs.form.resetFields();
      }
    },
    formEditar(_, data) {
      //capturamos los datos del registro seleccionado y los mostramos en el formulario
      this.modal = true;
      this.operacion = "editar";
      this.accion = "Actualizar";
      this.tituloModal = "Editar Diametro";
      this.form.id = data.id;
      this.form.descripcion = data.descripcion;
      this.form.valor = data.valor;
      if (typeof this.$refs.form != "undefined") {
        this.$refs.form.clearValidate();
      }
    },
    // Paginación
    paginate(val) {
      this.page = val;
    },
  },
};
</script>

<style></style>
